<template>
  <v-container id="task-pool-tables" fluid tag="section">
    <ConfirmDialog
      :isShow="confirmData.isConfirm"
      :title="confirmData.confirm_title"
      :message="confirmData.confirm_msg"
      :selectItem="confirmData.selectItem"
      :selectAction="confirmData.selectAction"
      v-on:onClose="onClose"
      v-on:onConfirm="onConfirm"
    ></ConfirmDialog>
    <base-material-card
      icon="mdi-clipboard-text"
      title="Task Pool"
      class="px-5 py-3"
    >
      <v-btn color="primary" dark class="mb-2" @click="confirmStartSchedule">
        <v-icon left>mdi-database-remove</v-icon>
        {{ $t("clear-pools") }}
      </v-btn>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :footer-props="footerProps"
        :loading="loading"
        multi-sort
        :show-select="isSelect"
        :single-select="false"
        dense
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        @page-count="pageCount = $event"
      >
        <template v-slot:header>
          <tr>
            <td></td>
            <td v-if="isSelect"></td>
            <td>
              <v-select
                v-model="filterItem.city"
                :label="$t('city')"
                :items="city_info"
                outlined
                dense
                clearable
                @change="onCityChange"
              ></v-select>
            </td>
            <td>
              <v-select
                v-model="filterItem.district"
                :label="$t('district')"
                :items="district_info"
                outlined
                dense
                clearable
                :item-text="(item) => item.name + '(' + item.code + ')'"
                item-value="code"
                @change="onAreaChange"
              ></v-select>
            </td>
            <td>
              <v-select
                v-model="filterItem.region"
                :label="$t('region')"
                :items="region_info"
                outlined
                dense
                clearable
                :item-text="getRegionDisplay"
                item-value="code"
                @change="onChange"
              ></v-select>
            </td>

            <td>
              <v-text-field
                v-model="filterItem.lbkey"
                :label="$t('lbkey')"
                outlined
                dense
                clearable
                @change="onChange"
              ></v-text-field>
            </td>
            <td>
              <v-select
                v-model="filterItem.system"
                :label="$t('system')"
                :items="spider_system"
                outlined
                dense
                clearable
                @change="onChange"
              ></v-select>
            </td>
            <td>
              <v-select
                v-model="filterItem.action"
                :label="$t('action')"
                :items="spider_actions"
                outlined
                dense
                clearable
                @change="onChange"
              ></v-select>
            </td>
            <td>
              <v-select
                v-model="filterItem.option"
                :label="$t('option')"
                :items="spider_options"
                outlined
                dense
                clearable
                @change="onChange"
              ></v-select>
            </td>
            <td>
              <v-text-field
                v-model="filterItem.rules"
                :label="$t('rule')"
                outlined
                dense
                clearable
                @change="onChange"
              ></v-text-field>
            </td>
            <td>
              <v-select
                v-model="filterItem.status"
                :label="$t('status')"
                :items="status_items"
                outlined
                dense
                clearable
                @change="onChange"
              ></v-select>
            </td>
            <td>
              <v-text-field
                :label="$t('create time')"
                outlined
                dense
                clearable
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                :label="$t('update time')"
                outlined
                dense
                clearable
              ></v-text-field>
            </td>
            <td></td>
          </tr>
        </template>
        <template v-slot:item.status="{ item }">
          <v-edit-dialog @save="updateStatus(item)" large>
            {{ item.status | getText(status_items) }}
            <template v-slot:input>
              <!-- <v-text-field v-model="item.status" label="Edit" single-line counter></v-text-field> -->
              <v-row align="baseline">
                <v-select
                  v-model="item.status"
                  :label="$t('status')"
                  :items="status_items"
                  large
                ></v-select>
              </v-row>
            </template>
          </v-edit-dialog>
        </template>
        <template dense v-slot:item.action="{ item }">{{
          item.action | getText(spider_actions) | Translate
        }}</template>
        <template v-slot:item.option="{ item }">{{
          item.option | getText(spider_options)
        }}</template>
        <template v-slot:item.system="{ item }">{{
          item.system | getText(spider_system) | Translate
        }}</template>
        <template v-slot:item.rules="{ item }">{{
          getPartial(item.rules)
        }}</template>
        <template v-slot:item.create_time="{ item }">{{
          item.create_time | moment("YYYY-MM-DD HH:mm:ss")
        }}</template>
        <template v-slot:item.update_time="{ item }">{{
          item.update_time | moment("YYYY-MM-DD HH:mm:ss")
        }}</template>
      </v-data-table>
    </base-material-card>
    <v-pagination
      v-model="page"
      :length="pageCount"
      :total-visible="20"
    ></v-pagination>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import {
  city_info,
  status_items,
  oc_option,
  systemOption,
  action_type,
} from "@/definition.js";
import ConfirmDialog from "../dialogs/ConfirmDialog";

export default {
  data: (vm) => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    selected: [],
    info_dialog: false,
    dialog_form: false,
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [10, 20, 50],
    },
    editedItem: {
      city: "",
      district: "",
      region: "",
    },
    defaultItem: {
      city: "",
      district: "",
      region: "",
    },
    filterItem: {
      city: null,
      district: null,
      region: null,
      lbkey: null,
      action: null,
      status: null,
      rules: null,
      create_time: {},
      update_time: {},
      system: null,
    },
    confirmData: {
      isConfirm: false,
      confirm_title: "啟動排程",
      confirm_msg: "",
      selectItem: null,
      selectAction: 0,
    },
    editedIndex: -1,
    action: "",
    items: [],
    totalItems: 0,
    loading: false,
    options: {},
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("id"),
        value: "id",
      },
      {
        sortable: false,
        text: vm.$i18n.t("city"),
        value: "city",
      },
      {
        sortable: false,
        text: vm.$i18n.t("district"),
        value: "district",
      },
      {
        sortable: false,
        text: vm.$i18n.t("region"),
        value: "region",
      },
      {
        sortable: false,
        text: vm.$i18n.t("lbkey"),
        value: "lbkey",
      },
      {
        sortable: false,
        text: vm.$i18n.t("system"),
        value: "system",
      },
      {
        sortable: false,
        text: vm.$i18n.t("action"),
        value: "action",
      },
      {
        sortable: false,
        text: vm.$i18n.t("option"),
        value: "option",
      },
      {
        sortable: false,
        text: vm.$i18n.t("rules"),
        value: "rules",
      },
      {
        sortable: false,
        text: vm.$i18n.t("status"),
        value: "status",
      },
      {
        sortable: false,
        text: vm.$i18n.t("create_time"),
        value: "create_time",
      },
      {
        sortable: false,
        text: vm.$i18n.t("update_time"),
        value: "update_time",
      },
    ],
    city_info: city_info,
    district_info: [],
    region_info: [],
    status_items: status_items,
    spider_actions: action_type,

    spider_options: oc_option,
    spider_system: systemOption,
    selected_spider: {
      uuid: "",
      name: "",
    },
    spiders: [],
  }),
  props: ["pack_id"],
  watch: {
    options: {
      handler() {
        this.getApi();
      },
    },
  },
  components: {
    ConfirmDialog,
  },
  computed: {
    ...mapState(["token"]),
    isSelect() {
      if (this.pack_id == null) return true;
      return false;
    },
  },
  methods: {
    getPartial(item) {
      if (item != null) {
        let data = item.substring(0, 30);
        if (item.length > 30) {
          data += "....";
        }
        return data;
      }
      return item;
    },
    getRegionDisplay(item) {
      return item.name + "(" + item.code + ") [" + item.land_keys + "]";
    },
    getApi() {
      this.loading = true;
      const currentObj = this;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/taskpools/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {
          page: page,
          page_size: itemsPerPage,
        },
      };
      for (const [key, value] of Object.entries(this.filterItem)) {
        if (!this.$common_util.isEmpty(value)) {
          config.params[key] = value;
        }
      }
      if (!this.$common_util.isEmpty(this.pack_id)) {
        config.params["pack__id"] = this.pack_id;
      }
      if (!this.$common_util.isEmpty(this.filterItem.filterItem)) {
        config.params["status__lt"] = 9;
      }

      this.axios
        .get(url, config)
        .then(function (response) {
          // console.log(response.data);
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        })
        .finally(() => {
          currentObj.loading = false;
        });
    },
    postApi() {},
    setResult(data) {
      let info = JSON.parse(JSON.stringify(data));
      this.totalItems = data.count;
      this.items = data.results;
    },
    createItem() {
      this.action = "create";
    },
    close() {
      // console.log("close me.");
      this.dialog_form = false;
      setTimeout(() => {
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      console.log(this.editedItem);
      if (this.editedIndex > -1) {
        this.patchApi(this.editedIndex);
        this.close();
      } else {
        this.postApi();
        this.close();
      }
      console.log("save");
    },
    getAreaApi() {
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/districts/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {},
      };
      config.params["city__code"] = this.filterItem.city;

      this.axios
        .get(url, config)
        .then(function (response) {
          //   console.log(response.data);
          currentObj.district_info = response.data;
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    getRegionApi() {
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/regions/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {
          city__code: this.filterItem.city,
          district__code: this.filterItem.district,
        },
      };

      // console.log(config);
      this.axios
        .get(url, config)
        .then(function (response) {
          // console.log(response.data);
          currentObj.region_info = response.data;
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    onCityChange() {
      this.getAreaApi();
      this.onChange();
    },
    onAreaChange() {
      this.getRegionApi();
      this.onChange();
    },
    onChange() {
      this.getApi();
    },
    dispatchTask(item) {
      console.log("task status " + item.status);
      if (item.status == 0 || item.status == 2) {
        console.log(item);
        // 1. get available spiders detail

        this.editedItem = JSON.parse(JSON.stringify(item));
        const currentObj = this;
        currentObj.dialog_form = true;
        const url =
          process.env.VUE_APP_SERVER_URL + "/backend/spider_process/available/";
        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + this.token,
          },
          params: {
            car: item.city,
          },
        };

        // console.log(config);
        this.axios
          .get(url, config)
          .then(function (response) {
            console.log(response.data);
            currentObj.spiders = response.data;
          })
          .catch(function (error) {
            console.log(error);
            console.log(error.response);
          });
      } else {
        console.log("Not allow to dispatch task!!!! ");
        this.info_dialog = true;
      }
    },
    postDispatchTask() {
      const currentObj = this;
      currentObj.dialog_form = true;
      let ids_array = [];
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/spider_process/dispatchTask/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      ids_array.push(this.editedItem.id);
      this.selected.forEach((item) => {
        ids_array.push(item.id);
      });

      let jsonData = {
        uuid: this.selected_spider,
        city: this.editedItem.city,
        ids: ids_array.toString(),
      };

      this.axios
        .post(url, jsonData, config)
        .then(function (response) {
          // console.log(response.data);
          currentObj.close();
          currentObj.getApi();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    updateStatus(item) {
      console.log("save " + item.id + " name" + item.status);
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/taskpools/" + item.id + "/";
      let jsonData = {
        status: item.status,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      console.log("json " + jsonData);
      this.axios
        .patch(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    // start schedule
    confirmStartSchedule(item) {
      this.confirmData.selectAction = item.mission_action;
      this.confirmData.confirm_msg = `清除Pool: 完整掃描`;
      this.confirmData.isConfirm = true;
    },
    onClose() {
      this.confirmData.isConfirm = false;
      this.confirmData.confirm_msg = "";
      this.confirmData.selectAction = 0;
    },
    onConfirm(data) {
      let _action = data.action;
      let _item = data.item;

      this.clearPool();
      this.onClose();
    },
    clearPool() {
      console.log("clear pool which task is not in tasks");

      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/taskpools/clear/";
      let jsonData = {};
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      console.log("json " + jsonData);
      this.axios
        .post(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
  },

  mounted() {
    console.log("pack_id " + this.pack_id);
    // this.$options.sockets.onmessage = this.receiveSocketData;
  },
};
</script>
